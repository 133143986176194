// This file was automate generate from backend Golang.
// !! Do not try to modify to this file because it will be replace.
export const DefinedVar = {
    RoleTenantAdminLevel: 17,
    ManagementSession: 'management_session',
    CheckInViaScreening: 'screening_process',
    RoleTenantMemberLevel: 1,
    CitizenSession: 'citizen_session',
    CheckInViaOneChatCheckIn: 'onechat_checkin',
    RiskRed: 'red',
    RoleTenantClientLevel: 2,
    RoleTenantStaffLevel: 10,
    RoleTenantOwnerLevel: 18,
    RoleTenantCreatorLevel: 19,
    RoleSystemPresaleLevel: 60,
    RoleSystemViewerLevel: 90,
    RoleSystemModeratorLevel: 98,
    CheckInViaQrCodeCheckIn: 'qrcode_checkin',
    CheckInViaThaiChana: 'thaichana_qrcode_checkin',
    RiskGreen: 'green',
    RiskYellow: 'yellow',
    RoleTenantGuestLevel: 0,
    RoleSystemSalesLevel: 50,
    RoleSystemRootLevel: 99,
    CheckInViaApiClient: 'api_client_checkin',
    RiskOrange: 'orange',
    ServerKey: 'LU2OTPCxskO738ll4EnocrsU2wiVxeJV',
};
