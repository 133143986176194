import { Injectable } from '@angular/core';
import { ApiService, Result } from 'src/app/core/services/api.service';
import { generateSalt, encryptSecret, decryptSecret } from 'src/app/shared/operators/util.operator';
import { HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
export interface LoginTicket {
    expires_at: string;
    ticket: string;
}

export interface VerifiedSignInResult {
    access_token: string;
    refresh_token: string;
    token_type: string;
    authorized: string;
}

export interface AccountProfile {
    account_id: string;
    created_at: Date;
    name: string;
    first_name_th: string;
    last_name_th: string;
    telephone: string;
    email: string;
    loa: string;
    avatar: string;
    admin_role: any;
    last_seen: Date;
    online: boolean;
    nickname: string;
    role_level: number;
    joined_at: Date;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private isAuth: boolean | undefined;
    private profile: AccountProfile | undefined;
    constructor(
        private apiService: ApiService,
    ) {
    }

    private verify = async (token: string, isOneChat = false): Promise<Result> => {
        const salt = generateSalt(32);
        const params = new HttpParams().append('salt', `${salt}`);
        const r = await this.apiService
            .get(`/v1/auth/ticket`, params)
            .toPromise()
            .then((result) => result.data);
        const secret = encryptSecret(token, salt);
        const data = {
            secret,
            ticket: (r as LoginTicket).ticket,
            onechat: isOneChat,
        } as object;
        return this.apiService
            .post(`/v1/auth/signin`, data)
            .pipe(
                tap(
                    (result) => {
                        const verified = result.data as VerifiedSignInResult;
                        localStorage.setItem('access_token', verified.access_token);
                        localStorage.setItem('refresh_token', verified.refresh_token);
                        localStorage.setItem('token_type', verified.token_type);
                        localStorage.setItem('salt', salt);
                        if (verified.authorized) {
                            localStorage.setItem('authorized', verified.authorized);
                        }
                        this.isAuth = true;
                    },
                    () => {
                        localStorage.clear();
                        this.isAuth = false;
                    }
                )
            )
            .toPromise();
    }

    getSignInUrl = (): string => `/api/v1/auth/signin`;

    verifyByOneIdAuthorizedCode = async (code: string): Promise<Result> => {
        return this.verify(code, false);
    }

    verifyByOneChatToken = async (token: string): Promise<Result> => {
        return this.verify(token, true);
    }

    isAuthorizedAccess = async (): Promise<boolean> => {
        if (this.isAuth === undefined) {
            return this.apiService
                .get('/v1/auth')
                .toPromise()
                .then(() => {
                    this.isAuth = true;
                    return true;
                })
                .catch((err) => {
                    this.isAuth = false;
                    localStorage.clear();
                    return false;
                });
        }
        return this.isAuth;
    }

    resetAuthorized = (): void => {
        localStorage.clear();
        this.isAuth = false;
    }

    signOut = async (): Promise<Result> => {
        this.isAuth = undefined;
        return this.apiService.get(`/v1/auth/signout`).toPromise();
    }

    getProfile = async (): Promise<Result> => {
        if (this.profile) {
            return {
                data: this.profile,
            } as Result;
        }
        return this.apiService.get(`/v1/auth/profile`).pipe(
            tap(
                (r) => {
                    this.profile = r.data as AccountProfile;
                },
            )
        ).toPromise();
    }

    getAvartarUrl = async (): Promise<Result> => {
        return this.apiService.get(`/v1/auth/profile/avatar`).toPromise();
    }

    checkSystemAuthorized = (lvl: number): boolean => {
        const auth = localStorage.getItem('authorized') || '';
        const salt = localStorage.getItem('salt') || '';
        if (auth !== '' && salt !== '') {
            const authLvl = +decryptSecret(auth, salt);
            if (authLvl >= lvl) {
                return true;
            }
        }
        return false;
    }
}
